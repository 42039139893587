const SAMPLE_CROSSHAIRS = [
  '0;p;0;s;1;P;o;1;f;0;0t;1;0l;2;0a;1;0f;0;1l;0;A;o;1;d;1;z;1;0l;0;1l;0;S;c;0;s;0.75;o;1',
  '0;s;1;P;c;8;h;0;b;1;0l;4;0o;0;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0',
  '0;c;1;P;u;000000FF;h;0;d;1;z;1;f;0;m;1;0t;1;0l;2;0v;5;0o;1;0a;1;0e;0.5;1b;0',
  '0;s;1;P;c;1;o;1;0t;1;0l;1;0o;2;0a;1;0f;0;1t;1;1l;1;1o;3;1a;0;1m;0;1f;0;S;o;10;s;1;P;c;8;u;000000FF;h;0;b;1;f;0;0l;3;0o;2;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0;S;o;10;P;c;7;t;2;o;1;d;1;z;3;a;0.374;f;0;s;0;0t;10;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;1;o;1;0t;1;0l;3;0a;1;0f;0;1t;1;1l;0;1o;2;1a;0.553;1m;0;1f;0;S;s;0.652',
  '0;s;1;P;c;7;u;FF0000FF;h;0;0l;20;0o;0;0a;1;0f;0;1b;0',
  '0;P;c;8;u;FF99FFFF;h;0;b;1;0l;4;0o;1;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;p;0;s;1;P;c;5;u;420690FF;o;1;f;0;0t;1;0l;2;0v;3;0o;2;0a;1;0f;0;1b;0;A;o;1;d;1;0l;0;0o;2;0a;1;1b;0',
  '0;P;c;5;h;0;f;0;0t;1;0l;5;0o;1;0a;1;0f;0;1t;3;1l;3;1o;2;1a;0.3;1m;0;1f;0',
  '0;p;0;s;1;P;c;5;u;420690FF;o;1;f;0;0t;1;0l;2;0v;3;0o;2;0a;1;0f;0;1b;0;A;o;1;d;1;0l;0;0o;2;0a;1;1b;0',
  '0;P;o;0;0b;0;1t;6;1l;1;1o;3;1a;1;1m;0;1f;0',
  '0;p;0;s;1;P;u;7D0901FF;o;0;f;0;m;1;0l;4;0v;5;0o;0;0a;1;0f;0;1b;0;A;o;1;d;1;0b;0;1b;0',
  '0;P;o;1;0t;1;0o;1;0a;1;0f;0;1b;0',
  '0;P;h;0;f;0;0t;4;0l;1;0o;2;0a;1;0f;0;1b;0',
  '0;P;o;1;f;0;s;0;0t;1;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;h;0;0l;3;0o;0;0a;1;0f;0;1b;0',
  ';P;c;1;o;1;0l;3;0o;5;0a;1;0f;0;1b;0',
  '0;P;c;1;h;0;f;0;0l;4;0a;1;0f;0;1b;0',
  '0;s;1;P;c;1;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0',
  '0;P;h;0;f;0;0l;4;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;h;0;0l;5;0v;0;0g;1;0a;1;0f;0;1l;0;1v;4;1g;1;1o;2;1a;1;1m;0;1f;0;S;c;5;o;1',
  '0;c;1;P;c;7;t;3;o;1;f;0;0t;10;0l;1;0v;0;0g;1;0o;4;0a;1;0f;0;1t;9;1o;0;1a;1;1m;0;1f;0',
  '0;s;1;P;h;0;f;0;s;0;0l;5;0o;2;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0',
  '0;P;h;0;f;0;0t;1;0l;4;0o;1;0a;1;0f;0;1t;3;1o;2;1a;1;1m;0;1f;0',
  '0;P;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;P;c;1;o;1;f;0;0l;3;0o;5;0a;1;0f;0;1t;0;1a;0.379',
  '0;P;c;1;h;0;m;1;0l;4;0o;2;0a;1;0f;0;1t;6;1l;0;1o;3;1a;0;1m;0;1f;0',
  '0;s;1;P;h;0;f;0;0l;5;0o;0;0a;1;0f;0;1b;0',
  '0;P;c;5;h;0;0l;4;0o;2;0a;1;0f;0;1a;0;1m;0;1f;0',
  '0;P;c;5;o;1;0t;1;0l;3;0o;1;0a;1;0f;0;1b;0',
  '0;p;0;s;1;P;h;0;0l;4;0o;0;0a;1;0f;0;1b;0;A;o;1;d;1;0b;0;1b;0',
  '0;s;1;P;c;7;o;1;d;1;0b;0;1b;0;S;c;5;s;0.64;o;1',
  '0;s;1;P;h;0;f;0;0l;5;0o;0;0a;1;0f;0;1b;0;S;o;1',
  '0;P;c;1;o;1;f;0;0l;3;0o;5;0a;1;0f;0;1b;0',
  '0;P;c;1;h;0;d;1;z;1;0t;1;0l;2;0a;1;0f;0;1l;5;1o;4;1a;1;1m;0;1f;0',
  '0;P;h;0;0l;5;0o;0;0a;1;0f;0;1b;0',
  '0;P;c;1;o;1;f;0;0l;5;0a;0.5;0f;0;1b;0',
  '0;P;c;1;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;o;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0;S;d;0',
  '0;P;c;5;u;00020EFF;o;1;d;1;f;0;0b;0;1b;0',
  '0;P;h;0;d;1;f;0;0l;4;0v;3;0g;1;0o;0;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;P;c;5;u;00020EFF;o;1;d;1;f;0;0b;0;1b;0',
  '0;P;o;1;f;0;0l;4;0v;4;0o;2;0a;1;0f;0;1b;0',
  '0;p;0;s;1;P;h;0;0t;1;0l;3;0o;2;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0;A;d;1;f;0;s;0;0b;0;1b;0;S;o;0.502',
  '0;P;c;7;o;1;f;0;0t;1;0l;2;0v;2;0g;1;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;h;0;0l;3;0v;4;0o;0;0a;1;0f;0;1b;0;S;c;0;s;0.64',
  '0;s;1;P;u;FFA500FF;o;1;d;1;f;0;s;0;0b;0;1t;0;1l;1;1o;0;1a;1;1m;0;1f;0;S;o;1',
  '0;s;1;P;c;5;o;1;d;1;z;3;f;0;0b;0;1b;0;S;s;0.628;o;1',
  '0;s;1;P;o;1;f;0;0l;4;0o;2;0a;1;0f;0;1b;0;S;c;5;s;1.142;o;0.829',
  '0;P;c;1;h;0;0l;4;0o;2;0a;1;0f;0;1b;0',
  '0;P;c;1;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0',
  '0;p;0;P;c;5;u;A020F0FF;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0',
  '0;P;u;A020F0FF;o;0.298;f;0;0t;1;0l;4;0o;1;0a;1;0f;0;1b;0',
  '0;s;1;P;u;000000FF;o;1;d;1;0b;0;1b;0;S;s;0.628;o;1',
  '0;c;1;s;1;P;c;5;u;00126DFF;h;0;f;0;0l;4;0v;4;0o;2;0a;1;0f;0;1b;0;S;c;5;s;0.848;o;1',
  '0;s;1;P;u;000000FF;o;1;f;0;0l;3;0a;1;0f;0;1b;0;S;b;1;c;8;t;00008BFF',
  '0;P;c;8;u;0000FFFF;h;0;b;1;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;p;0;s;1;P;c;7;u;64AAE6FF;h;0;f;0;0l;5;0o;0;0a;1;0f;0;1b;0;A;c;7;u;EF92BFFF;o;1;d;1;f;0;s;0;0b;0;1b;0;S;o;1',
  '0;p;0;s;1;P;o;1;f;0;0t;1;0l;2;0o;2;0a;1;0f;0;1b;0;A;o;1;d;1;0b;0;1b;0;S;s;0.359;o;1',
  '0;s;1;P;u;003300FF;o;1;d;1;0t;0;0l;16;0v;11;0g;1;0o;0;0f;0;1b;0;S;c;0;s;1.417;o;1',
  '0;p;0;s;1;P;c;5;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0;A;h;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;p;0;s;1;P;u;000000FF;h;0;f;0;0l;4;0v;4;0g;1;0o;0;0a;1;0f;0;1b;0;A;o;1;d;1;0b;0;1b;0;S;c;1',
  '0;s;1;P;h;0;s;0;0o;0;0a;1;0f;0;1b;0;S;d;0',
  '0;p;0;P;c;1;u;00008BFF;h;0;f;0;s;0;0l;10;0v;8;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;o;1;d;1;z;3;f;0;0t;6;0l;0;0a;1;0f;0;1b;0;S;c;6;s;0.949;o;1',
  '0;P;c;7;o;1;f;0;s;0;0t;1;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;1;u;910091FF;h;0;f;0;0t;1;0l;4;0o;1;0a;1;0f;0;1b;0;S;c;5;o;0',
  '0;s;1;P;c;6;o;1;d;1;z;1;f;0;s;0;0t;1;0l;1;0o;0;0a;1;0f;0;1b;0',
  '0;P;h;0;s;0;0l;4;0v;0;0o;0;0a;1;0f;0;1b;0',
  '0;P;c;7;o;1;f;0;0t;1;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;7;u;625999FF;h;0;d;1;z;4;0b;0;1b;0',
  '0;s;1;P;c;5;h;0;0l;3;0o;0;0a;0.802;0f;0;1b;0',
  '0;c;1;s;1;P;c;5;u;999999FF;o;1;d;1;f;0;0b;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;s;1;P;c;5;t;4;o;1;d;1;0t;10;0l;17;0o;20;0a;1;0m;1;0e;3;1t;6;1l;4;1o;40;1a;0.575;1s;3;1e;3',
  '0;s;1;P;o;1;d;1;z;3;f;0;0b;0;1b;0;S;s;0.762',
  '0;P;c;1;o;1;f;0;0t;1;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;u;072A7CFF;o;1;d;1;0l;0;0a;1;0f;0;1b;0;S;o;1',
  '0;P;c;8;u;000000FF;h;0;d;1;b;1;z;1;f;0;s;0;0t;1;0l;1;0o;0;0a;1;0e;0.2;1b;0',
  '0;P;c;5;o;1;d;1;z;3;0t;1;0l;1;0o;1;0a;1;0f;0;1b;0',
  '0;P;c;1;u;7FFFD4FF;o;1;d;1;0b;0;1b;0',
  '0;P;h;0;d;1;f;0;0l;2;0v;3;0a;1;0f;0;1b;0',
  '0;P;c;7;t;2;o;1;d;1;z;3;a;0;f;0;0t;10;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;u;33DD00FF;h;0;0l;3;0o;2;0a;1;0f;0;1t;1;1l;0;1o;0;1m;0;1f;0',
  '0;P;c;5;h;0;d;1;f;0;s;0;0l;1;0o;1;0a;1;0f;0;1b;0',
  '0;P;c;4;o;1;0t;1;0l;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;u;FF0060FF;h;0;0l;5;0v;3;0o;0;0a;1;0f;0;1b;0;S;s;0.688',
  '0;p;0;s;1;P;u;000000FF;h;0;f;0;s;0;0l;4;0v;20;0o;0;0a;1;0f;0;1b;0;A;o;1;d;1;0b;0;1b;0;S;c;0',
  '0;P;o;1;f;0;0l;3;0a;1;0f;0;1b;0',
  '0;P;u;FC4C4EFF;h;0;m;1;0t;1;0l;1;0o;0;0a;1;0f;0;1t;1;1o;2;1a;1;1m;0;1f;0',
  '0;p;0;s;1;P;h;0;0l;5;0o;2;0a;1;0f;0;1b;0;A;o;1;d;1;f;0;s;0;0b;0;1b;0;S;s;1.263;o;1',
  '0;P;h;0;f;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;4;o;1;m;1;0t;1;0l;1;0a;0;0f;0;1t;1;1l;1;1o;4;1a;0;1m;0;1f;0;S;c;0;s;0.591;o;1',
  '0;P;h;0;f;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;8;u;000000FF;h;0;d;1;b;1;f;0;m;1;0l;3;0o;0;0a;1;0e;0.5;1b;0;S;s;0.8;o;1',
  '0;s;1;P;c;5;u;0011FFFF;h;0;0l;4;0v;3;0o;2;0a;1;0f;0;1b;0;S;o;1',
  '0;p;0;c;1;s;1;P;c;8;u;000000FF;h;0;b;1;f;0;m;1;0l;2;0v;3;0o;1;0a;1;0f;0;1b;0;A;c;8;u;000000FF;h;0;b;1;f;0;s;0;0l;3;0o;0;0a;1;0f;0;1b;0;S;c;6;s;0.982',
  '0;s;1;P;c;8;u;005BFFFF;h;0;b;1;f;0;0l;4;0o;1;0a;0.295;0f;0;1o;2;1a;1;1m;0;1f;0',
  '0;s;1;P;u;000000FF;h;0;f;0;0l;4;0v;4;0o;0;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0;S;c;6;s;0.897;o;1',
  '0;P;c;7;o;1;f;0;0t;1;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;P;c;5;o;1;d;1;z;3;f;0;0b;0;1b;0',
  '0;P;c;8;u;00022EEFF;o;1:d;1;b;1;f;0;0b;0;1b;0',
  '0;P;c;8;u;000000FF;h;0;b;1;0b;0;1l;4;1v;4;1o;0;1a;1;1m;0;1f;0',
  '0;s;1;P;c;5;h;0;f;0;0l;3;0o;1;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;s;1;P;c;1;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0;S;c;1;s;0.628',
  '0;P;c;5;o;1;d;1;z;3;f;0;0b;0;1b;0',
  '0;P;c;1;o;1;f;0;0l;4;0o;2;0a;1;0f;0;1b;0',
  '0;P;o;0;0t;1;0l;2;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;u;000000FF;h;0;f;0;m;1;0l;3;0v;3;0o;2;0a;1;0f;0;1b;0',
  '0;P;h;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;P;h;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;P;h;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;P;h;0;f;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;P;h;0;d;1;f;0;0l;3;0v;2;0g;1;0o;2;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;P;c;5;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;1;h;0;f;0;0l;4;0v;5;0o;1;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0;S;o;1',
  '0;p;0;P;u;000000FF;h;0;d;1;z;1;f;0;m;1;0t;1;0l;2;0o;2;0a;1;0e;0.8;1b;0',
  '0;p;0;s;1;P;c;4;u;DB0B8AFF;o;1;d;1;z;6;a;0;f;0;m;1;0t;10;0l;10;0v;0;0g;1;0o;17;0a;0;0f;0;1t;6;1l;0;1v;3;1g;1;1o;0;1a;1;1s;0.053;1e;0.206;A;c;4;u;DB0B8AFF;o;0.585;d;1;z;6;a;0;f;0;s;0;m;1;0t;6; 0l;0;0v;3;0g;1;0o;4;0a;1;0f;0;1t;3;1l;8;1v;0;1g;1;1o;18;1a;1;1m;0;1e;0.29;S;b;1;c;8;t;DDBB00FF',
  '0;s;1;P;c;5;h;0;f;0;m;1;0l;4;0o;2;0a;1;0f;0;1b;0;S;s;0.8;o;1',
  '0;p;0;s;1;P;c;1;u;00B5FFFF;o;1;d;1;f;0;0b;0;1b;0;A;c;1;u;000FFFFF;o;1;d;1;0b;0;1b;0;S;c;5;t;000000FF;s;0.701;o;1d',
  '0;P;c;1;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;h;0;0l;4;0v;4;0o;2;0a;1;0f;0;1b;0;S;o;1',
  '0;p;0;s;1;P;c;5;u;00FFDFFF;h;0;0l;4;0o;2;0a;1;0f;0;1b;0;A;c;8;u;00FFDFFF;o;1;d;1;b;1;0b;0;1b;0;S;c;4;o;1',
  '0;P;c;5;o;1;f;0;0t;1;0l;2;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;1;o;1;f;0;0l;4;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;s;1;P;c;5;o;1;f;0;0t;1;0l;2;0a;1;0f;0;1b;0;S;s;0.64;o;1',
  '0;s;1;P;c;2;o;1;d;1;f;0;0b;0;1b;0;S;c;0;o;1',
  '0;s;1;P;c;5;u;1B29C1FF;h;0;f;0;s;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;8;u;000000FF;h;0;b;1;f;0;0b;0;1t;3;1l;4;1o;2;1a;1;1m;0;1f;0;S;d;0',
  '0;p;0;P;u;000000FF;o;1;d;1;z;1;f;0;s;0;0t;0;0l;4;0v;2;0o;0;0a;1;0f;0;1b;0',
  '0;P;h;0;0t;1;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;4;u;DB0B8AFF;o;1;d;1;z;6;a;0;f;0;m;1;0t;10;0l;10;0v;0;0g;1;0o;17;0a;0;0f;0;1t;6;1l;0;1v;3;1g;1;1o;0;1a;1;1m;0;1e;0.281;S;b;1;c;8;t;DB0B8AFF',
  '0;P;h;0;0l;5;0o;0;0f;0;1b;0',
  '0;P;h;0;0t;1;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;P;h;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;5;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0;S;o;1',
  '0;s;1;P;h;0;f;0;0l;3;0o;2;0a;1;0f;0;1b;0;S;s;0.8;o;1',
  '0;P;c;5;f;0;0l;3;0v;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;o;1;f;0;0t;3;0l;8;0a;1;0f;0;1t;4;1l;0;1o;7;1a;1;1m;0;1f;0',
  '0;s;1;P;o;1;f;0;0t;3;0l;8;0a;1;0f;0;1t;4;1l;0;1o;7;1a;1;1m;0;1f;0',
  '0;P;c;5;h;0;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;c;8;u;C0C0C0FF;o;1;d;1;b;1;0b;0;1l;0;1a;0;S;c;0;o;1',
  '0;s;1;P;c;8;u;000000FF;h;0;b;1;f;0;m;1;0l;4;0v;4;0o;0;0a;1;0f;0;1b;0;S;s;0.677;o;1',
  '0;P;c;1;0t;1;0l;3;0o;2;0a;1;0f;0;1b;0',
  '0;s;1;P;h;0;0l;3;0o;2;0a;1;0f;0;1b;0;S;o;0',
  '0;s;1;P;c;5;h;0;0l;4;0o;0;0a;1;0f;0;1b;0;S;c;5;s;0;o;1',
  '0;P;c;1;h;0;f;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;c;8;u;000000FF;o;1;b;1;s;0;0t;3;0l;1;0v;0;0g;1;0o;0;0a;1;0f;0;1t;1;1l;4;1g;1;1o;0;1a;1;1m;0;1f;0;S;c;0;o;1',
  '0;c;1;P;c;5;t;3;o;1;f;0;0t;10;0l;1;0v;0;0g;1;0o;4;0a;1;0f;0;1t;9;1o;0;1a;1;1m;0;1f;0',
  '0;s;1;P;c;5;h;0;0l;4;0o;0;0a;1;0f;0;1b;0',
  '0;s;1;P;h;0;f;0;s;0;0l;4;0o;0;0a;1;0f;0;1b;0;S;s;0.7;o;1',
  '0;P;o;1;0t;1;0l;1;0o;2;0a;0.1;0f;0;1t;1;1l;1;1o;3;1a;0.1;1m;0;1f;0',
  '0;s;1;P;c;5;u;000000FF;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0;S;t;000000FF;o;1 0;s;1;P;u;000000FF;h;0;f;0;0l;4;0o;0;0a;1;0f;0;1b;0;S;c;0;o;1',
  '0;P;c;5;u;00FF5EFF;h;0;f;0;0t;1;0l;2;0v;3;0o;1;0a;1;0f;0;1t;0;1o;0;1a;1;1m;0;1f;0',
  '0;p;0;s;1;P;h;0;f;0;s;0;0l;3;0o;2;0a;1;0f;0;1b;0;A;c;5;o;0.852;d;1;0b;0;1b;0',
  '0;s;1;P;o;1;f;0;0t;1;0l;1;0o;2;0a;0;0f;0;1t;1;1l;1;1v;3;1o;3;1a;0;1m;0;1f;0;S;o;0.8',
  '0;P;h;0;0l;4;0o;0;0a;1;0f;0;1t;1;1l;1;1o;5;1a;0;1m;0;1f;0',
  '0;P;h;0;0l;4;0o;0;0a;1;0f;0;1t;1;1l;1;1o;5;1a;0;1m;0;1f;0',
  '0;P;c;1;o;1;d;1;0t;0;0l;0;0o;0;0a;0;0f;0;1t;0;1l;0;1o;0;1a;0;1m;0;1f;0',
  '0;s;1;P;c;1;h;0;0l;4;0o;2;0a;1;0f;0;1b;0;S;o;1',
  '0;s;1;P;c;1;0t;1;0l;3;0o;1;0a;1;0f;0;1b;0;S;c;1;s;0.75;o;1',
  '0;s;1;P;u;000000FF;h;0;0l;4;0o;0;0a;1;0f;0;1b;0;S;s;0.872;o;1',
  '0;p;0;s;1;P;c;6;h;0;f;0;0l;4;0o;2;0a;1;0f;0;1b;0;A;c;7;o;0;d;1;z;3;0b;0;1b;0',
  '0;P;o;1;d;1;z;3;0b;0;1b;0',
  '0;P;h;0;0t;5;0l;1;0o;2;0a;1;0f;0;1b;0',
  '0;c;1;s;1;P;c;5;h;0;0l;3;0o;0;0a;1;0f;0;1b;0;S;c;5;s;0.812;o;1',
  '0;P;h;0;f;0;0l;5;0v;5;0o;0;0a;1;0f;0;1b;0',
  '0;c;1;s;1;P;c;5;h;0;0l;3;0o;0;0a;1;0f;0;1b;0;S;c;5;s;0.812;o;1',
  '0;p;0;s;1;P;o;0;f;0;0t;1;0l;4;0o;1;0a;1;0f;0;1t;1;1l;1;1o;0;1a;0;1m;0;1f;0;A;o;1;0t;1;0l;1;0o;0;0a;0;0f;0;1t;1;1l;1;1o;0;1a;0;1m;0;1f;0',
  '0;s;1;P;c;1;u;000000FF;h;0;f;0;0l;3;0v;5;0o;2;0a;1;0f;0;1t;10;1l;0;1v;10;1o;13;1a;1;1m;0;1f;0;S;c;4;',
  '0;p;0;c;1;s;1;P;o;1;f;0;m;1;0t;1;0l;3;0a;1;0f;0;1b;0;A;c;7;h;0;d;1;z;3;m;1;0t;3;0l;2;0o;0;0a;0.5;0f;0;1b;0;S;s;0.5;o;1',
  '0;c;1;s;1;P;h;0;f;0;0l;3;0o;0;0a;1;0f;0;1b;0;S;c;0;s;0.322;o;1',
  '0;s;1;P;c;5;h;0;d;1;z;1;f;0;0t;3;0l;2;0o;0;0a;1;0f;0;1b;0;S;c;5;s;1.026;o;1',
  '0;P;h;0;f;0;0l;3;0o;0;0a;1;0f;0;1b;0',
  '0;p;0;s;1;P;c;1;o;1;0t;1;0l;2;0o;2;0a;1;0f;0;1t;0;1l;0;1o;0;1a;0;A;c;7;h;0;d;1;z;3;0b;0;1b;0',
];

export default SAMPLE_CROSSHAIRS;
